:root {
    --primary: #00afff;
    --info: #45A6DD;
    --error: #dc3545;
    --white: white;
    --secondary: #18304b;
}
@media(max-width:765px) {
    .nav.navbar-nav.navbar-right {
        display: flex;    
        flex-direction: column;
        align-items: flex-start;
    }
    .nav.navbar-nav.navbar-right li {
        padding: .25rem 0;
    }
}
p {
    font-size: 18px;
    line-height: 26px;
}
a:hover {
    color: var(--primary);
}
.bg-primary {
    background-color: var(--primary) !important;
}
.nav  li a {
    font-weight: 700;
}
.navbar {
    background-color: #FFF !important;
    color: var(--secondary) !important;
    padding: 0 1rem !important;
}
.navbar .container {
    align-items: center;
}
.custom-navbar .nav li a.reg-btn {
    background-color: var(--primary);
    color: #FFF !important;
    border: 0;
    padding: 0.5rem 1.5rem;
    margin-left: 1rem;
}
a {
    text-decoration: none;
    color: inherit;
}
.btn-primary {
    background-color: var(--primary) !important;
}
.bg-white {
    color: var(--primary);
    background-color: #fff !important;
}
.text-primary {
    color: var(--primary) !important;
}
.text-secondary {
    color: var(--secondary) !important;
}
.text-white {
    color: #fff !important;
}
.text-warning {
    color: var(--warning)
}
body, #notistack-snackbar {
    font-size: 18px !important;
}
.custom-navbar .nav li a {
    font-size: 16px;
    font-weight: 800;
    text-transform: capitalize;
}


button {
    text-transform: capitalize !important;
    box-shadow: none !important;
}
.text-white {
    color: white;
}
.text-left {
    text-align: left !important;
}
.f-20 {
    font-size: 20px !important;
}
.f-18 {
    font-size: 18px !important;
}
.f-16 {
    font-size: 16px !important;
}
.f-14 {
    font-size: 14px !important;
}
.brand {
    display: flex;
    align-items: center;
}

.brand img {
    /* height: 85px; */
    margin-right: 1rem;
    margin-top: 0;
}

.navbar-brand {
    font-size: 13.5px !important;
    margin: 0 !important;
    padding: 0 !important;
    color: var(--secondary) !important;
    /* text-align: center; */
}
.navbar-brand span {
    display: block;
    font-weight: 900;
    font-size: 22px !important;
    text-transform: uppercase;
    color: var(--primary) !important;
    line-height: 5px;
}
.custom-navbar .nav li a {
    color: var(--secondary) !important;
}
.custom-navbar .nav li a:hover {
    color: var(--primary) !important;
}

.success-section {
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10%;
    text-align: center;
}
.text-error {
    color: brown;
    display: block;
    font-size: 14px;
}
.h-divider {
    display: flex;
    align-items: center;
}
.auto-search {
    display: block;
    padding: 1.5rem;
    font-size: 14px;
    width: 100%;
    border-radius: inherit;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.23);
    outline: none !important;
}
.auto-search:focus-visible {
    border-color: var(--primary);
    border-width: 1.8;
}
.h-divider::before {
    display: block;
    content: "";
    border-bottom: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin: 0 1rem;
    border-top: 0.5px solid #444;
}
.h-divider::after {
    display: block;
    content: "";
    margin: 0 1rem;
    border-bottom: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    border-top: 0.5px solid #444;
}

@media(max-width:1200px) {
    .conference {
        color: var(--primary);
        font-weight: 900;
        font-size: 32px;
    }
    .navbar-brand {
        margin-top: 1rem;
    }
    .brand img {
        margin-top: 0;
    }
    .brand {
        flex: 1;
        padding: 1.5rem;
    }
    .navbar-header {
        align-items: center;
        display: flex;
        flex-direction: row-reverse;
    }
    .go-top {
        bottom: 1em;
        right: 0.5em;
        width: 40px;
        font-size: 25px;
        height: 40px;
        line-height: 0;
        display: flex !important;
        align-items: center;
        justify-content: center;
    }
    .go-top i {
        font-size: 20px;
    }
}
@media(max-width:800px) {
    .conference {
        color: var(--primary);
        font-weight: 900;
        font-size: 32px;
    }
    .count-down h3 {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
        color: var(--primary);
        font-weight: 900;
        font-size: 30px;
    }
    .navbar-brand {
        margin-top: 1rem;
    }
    .brand img {
        margin-top: 0;
    }
    .navbar-brand span {
        line-height: 22px;
    }
    .brand {
        flex: 1;
        padding: 1.5rem;
    }
    .navbar-header {
        align-items: center;
        display: flex;
        flex-direction: row-reverse;
    }
    .sponsors h3 {
        text-align: center;
    }
    .overlay-2 {
        height: auto !important;
    }
}

.custom-navbar .navbar-toggle {
    background-color: var(--primary) !important;
}

#register label, #form label {
    margin-top: .5rem;
    margin-bottom: .5rem;
    font-weight: normal;
    font-size: 14px;
}

#register button, .btn-custom, #form button {
    width: 100%;
    border-radius: 5px;
    min-height: 50px;
    text-transform: capitalize;
    background-color: #03a54f;
    width: 50%;
    margin: auto;
    display: block;
}
.btn-white {
    border-color: transparent;
    color: #fff;
    height: 40px;
    padding: 1rem;
    margin-bottom: 2rem;

}
#register .form-control, #form .form-control {
    border-radius: 5px;
    padding: 2rem;
    min-height: 50px;
    border: 1px solid #ffffff;
}
#detail.c-2 i {
    color: var(--white);
    margin-bottom: 1rem;
}
#detail.c-2 p{
    color: var(--secondary);
    font-weight: bold;
    font-size: 18px;
}
.overlay {
    width: 100%; height: 100%;
    display: flex;
    justify-content: center; align-items: center;
    background-color: rgba(0, 0, 0, 0.75);
}
.overlay-2 {
    width: 75%; 
    height: 50%;
    display: flex;
    color: var(--secondary);
    justify-content: center; align-items: center;
    background-color: rgba(255, 255, 255, 0.95);
}
.inner-banner .overlay {
    background-color: rgba(0, 0, 0, 0.85);
    align-items: flex-end;
    padding-bottom: 5rem;
}
.inner-banner h1 {
    font-size: 50px;
}
.inner-banner {
    height: 40vh !important;
    /* background: url('../public/assets/images/slider-2.jpg') 50% 0 repeat-y fixed; */
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center;
    color: #ffffff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
    padding-bottom: 0;
}
body {
    margin: 0;
    font-family: "Lato", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* font-size: 14px; */
}
h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-family: "Lato", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
p {
    font-family: "Lato", sans-serif;
    color: #161616;
}

.speakers h4 {
    margin-top: 1rem;
    font-size: 16px;
}
.speakers h6 {
    font-size: 11px;
    margin-top: 1rem;
}
.speakers img {
    height: 80% !important;
    object-fit: cover;
}
@media(max-width:800px) {
    .speakers img {
        height: 80% !important;
    }
}
#sponsors .speakers {
    padding: 2rem;
}
#sponsors img {
    height: 200px !important;
    object-fit: contain !important;
    display: block;
    margin: auto;
}
#hotels #sponsors .speakers {
    padding: 0;
    height: 285px !important;
}
#hotels #sponsors img {
    height: 200px !important;
    object-fit: cover !important;
    display: block;
    margin: auto;
}
.wrap-image {  
    float: left;   
    margin: 0 2rem 0 0;
} 
.text-justify {
    text-align: justify;
} 
/* ================================================================================================================================================= */
.f-24 {
    font-size: 24px !important;
} 
.f-26 {
    font-size: 26px !important;
} 
.f-28 {
    font-size: 28px !important;
} 
.mr-02 { margin-right: .2rem !important }
.mr-05 { margin-right: .5rem !important }
.no-padding {
    padding: 0 !important;
}
.no-margin {
    margin: 0 !important;
}
.m-0 { margin: 0 !important }
.m-05 { margin: 0.5rem !important }
.m-1 { margin: 1rem !important }
.m-2 { margin: 2rem !important }
.m-3 { margin: 3rem !important }
.m-4 { margin: 4rem !important }
.m-5 { margin: 5rem !important }
.m-6 { margin: 6rem !important }
.m-7 { margin: 7rem !important }
.m-8 { margin: 8rem !important }
.m-9 { margin: 9rem !important }
.m-10 { margin: 10rem !important }

.mt-0 { margin-top: 0 !important }
.mt-025 { margin-top: 0.25rem !important }
.mt-05 { margin-top: 0.5rem !important }
.mt-1 { margin-top: 1rem !important }
.mt-2 { margin-top: 2rem !important }
.mt-3 { margin-top: 3rem !important }
.mt-4 { margin-top: 4rem !important }
.mt-5 { margin-top: 5rem !important }
.mt-6 { margin-top: 6rem !important }
.mt-7 { margin-top: 7rem !important }
.mt-8 { margin-top: 8rem !important }
.mt-9 { margin-top: 9rem !important }
.mt-10 { margin-top: 10rem !important }

.mb-0 { margin-bottom: 0 !important }
.mb-05 { margin-bottom: .5rem !important }
.mb-1 { margin-bottom: 1rem !important }
.mb-2 { margin-bottom: 2rem !important }
.mb-3 { margin-bottom: 3rem !important }
.mb-4 { margin-bottom: 4rem !important }
.mb-5 { margin-bottom: 5rem !important }
.mb-6 { margin-bottom: 6rem !important }
.mb-7 { margin-bottom: 7rem !important }
.mb-8 { margin-bottom: 8rem !important }
.mb-9 { margin-bottom: 9rem !important }
.mb-10 { margin-bottom: 10rem !important }

.mr-0 { margin-right: 0 !important }
.mr-05 { margin-right: .5rem !important }
.mr-1 { margin-right: 1rem !important }
.mr-2 { margin-right: 2rem !important }
.mr-3 { margin-right: 3rem !important }
.mr-4 { margin-right: 4rem !important }
.mr-5 { margin-right: 5rem !important }
.mr-6 { margin-right: 6rem !important }
.mr-7 { margin-right: 7rem !important }
.mr-8 { margin-right: 8rem !important }
.mr-9 { margin-right: 9rem !important }
.mr-10 { margin-right: 10rem !important }

.ml-0 { margin-left: 0 !important }
.ml-05 { margin-right: .5rem !important }
.ml-1 { margin-left: 1rem !important }
.ml-2 { margin-left: 2rem !important }
.ml-3 { margin-left: 3rem !important }
.ml-4 { margin-left: 4rem !important }
.ml-5 { margin-left: 5rem !important }
.ml-6 { margin-left: 6rem !important }
.ml-7 { margin-left: 7rem !important }
.ml-8 { margin-left: 8rem !important }
.ml-9 { margin-left: 9rem !important }
.ml-10 { margin-left: 10rem !important }

.p-0 { padding: 0 !important }
.p-1 { padding: 1rem !important }
.p-2 { padding: 2rem !important }
.p-3 { padding: 3rem !important }
.p-4 { padding: 4rem !important }
.p-5 { padding: 5rem !important }
.p-6 { padding: 6rem !important }
.p-7 { padding: 7rem !important }
.p-8 { padding: 8rem !important }
.p-9 { padding: 9rem !important }
.p-10 { padding: 10rem !important }

.pt-0 { padding-top: 0 !important }
.pt-1 { padding-top: 1rem !important }
.pt-2 { padding-top: 2rem !important }
.pt-3 { padding-top: 3rem !important }
.pt-4 { padding-top: 4rem !important }
.pt-5 { padding-top: 5rem !important }
.pt-6 { padding-top: 6rem !important }
.pt-7 { padding-top: 7rem !important }
.pt-8 { padding-top: 8rem !important }
.pt-9 { padding-top: 9rem !important }
.pt-10 { padding-top: 10rem !important }

.pb-0 { padding-bottom: 0 !important }
.pb-1 { padding-bottom: 1rem !important }
.pb-2 { padding-bottom: 2rem !important }
.pb-3 { padding-bottom: 3rem !important }
.pb-4 { padding-bottom: 4rem !important }
.pb-5 { padding-bottom: 5rem !important }
.pb-6 { padding-bottom: 6rem !important }
.pb-7 { padding-bottom: 7rem !important }
.pb-8 { padding-bottom: 8rem !important }
.pb-9 { padding-bottom: 9rem !important }
.pb-10 { padding-bottom: 10rem !important }

.pr-0 { padding-right: 0 !important }
.pr-1 { padding-right: 1rem !important }
.pr-2 { padding-right: 2rem !important }
.pr-3 { padding-right: 3rem !important }
.pr-4 { padding-right: 4rem !important }
.pr-5 { padding-right: 5rem !important }
.pr-6 { padding-right: 6rem !important }
.pr-7 { padding-right: 7rem !important }
.pr-8 { padding-right: 8rem !important }
.pr-9 { padding-right: 9rem !important }
.pr-10 { padding-right: 10rem !important }

.pl-0 { padding-left: 0 !important }
.pl-1 { padding-left: 1rem !important }
.pl-2 { padding-left: 2rem !important }
.pl-3 { padding-left: 3rem !important }
.pl-4 { padding-left: 4rem !important }
.pl-5 { padding-left: 5rem !important }
.pl-6 { padding-left: 6rem !important }
.pl-7 { padding-left: 7rem !important }
.pl-8 { padding-left: 8rem !important }
.pl-9 { padding-left: 9rem !important }
.pl-10 { padding-left: 10rem !important }

/* ------------------------------------------------------------------------------------------------------------------ */
#faq, #contact {
    background-color: white !important;
    background: none;
}
.text-bold {
    font-weight: bold;
}
#intro {
    height: auto !important;
    margin-top: 5rem !important;
    /* background-size: cover !important;
    background-repeat: no-repeat !important; */
}
#intro .card {
    background-color: rgba(225, 238, 255, .85);
    padding: 35px;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    min-width: 95%;
    margin-top: -5rem;
}
#intro .card > div {
    max-width: 50%;
    margin: auto;
}
#intro h4 {
    font-size: 30px!important;
    margin: 0.5rem!important;
    color: var(--secondary);
    font-weight: bolder;
}
#intro h1 {
    font-size: 42px;
    font-weight: 800;
    color: var(--primary);
    line-height: 1.2;
    letter-spacing: -.9px;
}

#intro .text-input {
    background-color: #fff !important;
    border-radius: 7px;
}
#intro .text-input fieldset {
    display: none;
}
#intro .custom-btn {
    font-size: 14px !important;
    padding: 10px 12px;
    margin-right: -0.3rem;
}
.form-field input {
    padding: 0 1.5rem;
    font-size: 14px;
}
.text-input input {
    font-size: 14px;
}
.text-input > div {
    padding: 0.5rem 1rem 0.5rem 0;
}
label {
    font-size: 15px;
}
.form .form-field input {
    padding: 1.5rem;
}

@media (max-width: 1500px) {
    #intro .card > div {
        max-width: 60%;
        margin: auto;
    }

}

@media (min-width: 1200px) {
    #intro .card {
        margin-top: -18rem;
    }
}

@media (max-width: 1200px) {
    #intro .card {
        padding: 20px;
        margin-top: -10rem;
    }
    #intro h1 {
        font-size: 32px!important;
        margin: 0.5rem!important;
    }
    #intro h4 {
        font-size: 20px!important;
        margin: 0.5rem!important;
    }
    #intro p {
        font-size: 14px!important;
    }
    #intro .card > div {
        max-width: 70%;
        margin: auto;
    }
    .text-input input {
        padding: 1rem;
    }
}

@media (max-width: 767px) {
    #intro .card {
        padding: 20px;
        margin-top: -5rem;
    }
    #intro h1 {
        font-size: 26px!important;
        margin: 0.5rem!important;
    }
    #intro h4 {
        font-size: 16px!important;
        margin: 0.5rem!important;
    }    
    #intro .card > div {
        max-width: 85%;
        margin: auto;
    }
    #intro {
         margin-top: 4rem;
        /*background-attachment: initial !important;
        background-size: contain !important;
        background-repeat: no-repeat !important; */
    }
}

@media (max-width: 400px) {
    #intro .card {
        padding: 20px 15px;
        margin-top: -5rem;
    }
    #intro h1 {
        font-size: 24px!important;
    }
    #intro h4 {
        font-size: 14px!important;
        margin: 0.5rem!important;
    }    
    #intro .card > div {
        max-width: 90%;
    }
    #intro {
        /* margin-top: 2rem;
        background-attachment: initial !important;
        background-size: contain !important;
        background-repeat: no-repeat !important; */
    }
}
.custom-select {
    padding-top: 2rem !important;
}

.custom-select span, .custom-number-input {
    background: #e1eeff;
    padding: 15px;
    color: #000 !important;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    border: 0;
    border-radius: 10px;
    width: 100%;
    text-align: center;
}

.custom-select span.active {
    background: #0c66c7;
    color: #fff !important;
}

.custom-number-input span {
    display: block;
    margin-bottom: 0.5rem;
}

.custom-number-input input {
    margin-bottom: 0.5rem;
    text-align: center;
    padding: 0.5rem 0.25rem;
}

.custom-btn.btn-2 {
    background: var(--secondary);
}    
.custom-btn {
    background: #00afff;
    font-family: "Lato", sans-serif;
    text-transform: inherit !important;
    padding: 26px 35px;
    color: #fff !important;
    cursor: pointer;
    display: inline-block;
    font-size: 18px !important;
    font-weight: 600 !important;
    border-radius: 5px;
    line-height: 1;
    line-height: 0;
    cursor: pointer;
    -moz-user-select: none;
    transition: color .4s linear;
    position: relative;
    z-index: 1;
    border: 0;
    overflow: hidden;
}

.custom-btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 102%;
    height: 102%;
    background: #0c66c7;
    z-index: 1;
    border-radius: 0;
    transition: transform .5s;
    transition-timing-function: ease;
    transform-origin: 0 0;
    transition-timing-function: cubic-bezier(.5, 1.6, .4, .7);
    transform: scaleX(0);
    border-radius: 0;
}

.custom-btn:hover::before {
    transform: scaleX(1);
    z-index: -1;
}

.custom-btn.btn-2 {
    background: var(--secondary);
}    

.custom-btn.btn-2::before {
    background: #08213d;
}    

.loader {
    background-color: var(--white);
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 0;
    z-index: 9999999;
    right: 0;
    top: 0;
    bottom: 0;
}
.loader img {
    -webkit-animation: fadeinout 2s infinite forwards;
    animation: fadeinout 3.5s infinite forwards;
    height: 300px;
}
.loader .MuiLinearProgress-indeterminate {
    width: 15%;
    margin-top: 1rem;
}
@media (max-width: 960px) {
    .loader img, .loader .MuiLinearProgress-indeterminate  {
        width: 40%;
    }
}
@-webkit-keyframes fadeinout {
    0%,100% { opacity: 0; }
    50% { opacity: 1; }
}
  
@keyframes fadeinout {
    0%,100% { opacity: 0; }
    50% { opacity: 1; }
}


.mobile-menu li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mobile-menu ul {
    padding: 1rem;
}

.mobile-menu h3 {
    padding: 0.5rem 1rem;
}


/* .footer-social {
    display: flex;
    flex-direction: column;
    right: 0;
    top: 40%;
    position: fixed;
    bottom: 0;
    z-index: 9999999;
    right: 30px;
    left: auto;
} */

/* @media (max-width: 767px) { */
    .footer-social {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        right: auto;
        top: auto;
        position: relative;
        bottom: auto;
        left: auto;
        /* margin-bottom: 0px; */
    }
    .footer-social a {
        margin: 0 .5rem;
        font-size: 20px;
    }
/* } */